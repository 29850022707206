import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import {UploadFile} from '@mui/icons-material'

import {useTheme} from '@mui/material/styles'
import {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {LoadingButton} from '@mui/lab'
import SizeFormatter from '../SizeFormatter'
import {useCreateFiles} from '../../hooks/api-hooks'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Box sx={{width: '100%', mr: 1}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

function FileList({
                    files, onDeleteFile = () => {
  }
                  }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant={'subtitle2'} mb={2}>
        Fichiers ajoutées pour envoi
      </Typography>
      <Box ml={1} style={{overflow: 'auto', flex: 1}}>
        <ul>
          {files.map(file => (
            <li
              key={file.path}
              style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              {file.path} - <SizeFormatter sizeInBytes={file.size}/>
            </li>
          ))}
        </ul>
      </Box>
    </div>
  )
}

function DropZone({setFiles, files}) {
  const theme = useTheme()
  const addFiles = newFiles => {
    setFiles([...files, ...newFiles])
  }
  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const text = reader.result
        }
        reader.readAsText(file)
      })
      addFiles(acceptedFiles)
    },
    [addFiles],
  )

  const {getRootProps, isDragActive, acceptedFiles, open} = useDropzone({
    onDrop,
  })

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12} p={2}>
        <FileList files={files}/>
      </Grid>
      <Grid item md={6} xs={12} p={2}>
        <div
          {...getRootProps()}
          style={{
            border: '3px dashed #999',
            backgroundColor: isDragActive ? theme.palette.primary.main : '#eee',
            color: isDragActive ? 'white' : 'black',
            marginTop: '10px',
            padding: '50px',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <UploadFile/>
            <Typography variant={'overline'} ml={1}>
              Importer un ou plusieurs fichiers
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} textAlign={'right'} p={2}>
        <button type="button" onClick={open}>
          Parcourir
        </button>
      </Grid>
    </Grid>
  )
}

export default function AddDocumentFilesDialog({
                                                 open = true,
                                                 folderId = 1,
                                                 handleClose = () => {
                                                 },
                                                 ...props
                                               }) {
  const [files, setFiles] = useState([])
  const [uiState, setUiState] = useState('initial')
  const {mutate, isLoading, isError, error} = useCreateFiles({
    onUploadProgress: progressEvent => {
      setUploadProgress(progressEvent.loaded / progressEvent.total)
    },
  })
  const isInitial = uiState === 'initial'
  const isFinished = uiState === 'finished'
  const [uploadProgress, setUploadProgress] = useState(0)

  const upload = () => {
    setUploadProgress(0)
    mutate(
      {files, folderId},
      {
        onSuccess: () => {
          setFiles([])
          setUiState('finished')
        },
      },
    )
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="add-document-file-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        id="add-document-file-dialog"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <UploadFile/>
        Téléverser des fichiers
      </DialogTitle>
      <Divider/>
      <DialogContent>
        {isInitial || isError ? (
          <>
            <DropZone setFiles={setFiles} files={files}/>
            {isError ? (
              <Alert color="error">
                Une erreur est survenue: {error.toString()}
              </Alert>
            ) : undefined}
          </>
        ) : undefined}
        {isLoading ? (
          <>
            <Typography variant={'body'}>
              Téléversement de vos {files.length} fichiers en cours
            </Typography>
            <LinearProgressWithLabel
              variant="determinate"
              value={uploadProgress}
            />
          </>
        ) : undefined}
        {isFinished ? (
          <Typography variant={'body'}>
            Vos fichiers ont bien été téléversés
          </Typography>
        ) : undefined}
      </DialogContent>
      <DialogActions>
        {isFinished ? (
          <Button onClick={handleClose} variant="contained" color="primary">
            Retour
          </Button>
        ) : (
          <>
            <Button
              onClick={handleClose}
              color="error"
              disabled={isLoading}
              variant={'contained'}
            >
              Annuler
            </Button>
            <LoadingButton
              startIcon={<UploadFile/>}
              loading={isLoading}
              loadingPosition="start"
              variant={'contained'}
              onClick={upload}
            >
              Envoyer
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
